.errorPage{
  text-align: center;
  padding: 10rem 2rem;
  border: 3px solid #ddd;
  max-width: 69rem;
  margin: auto;
  margin-top: 5rem;
  h2{
    color: #444;
    font-size: 3.4rem;
    letter-spacing: 0;
    line-height: 5rem;
    margin-bottom: 2rem;
    font-weight: 500;
    @media (max-width:600px) {
        font-size: 2.4rem;
        line-height: 3rem;
    }
  }
  a{
    display: inline-block;
    color: #fff;
    letter-spacing: 0;
    font-weight: 600;
    border: 1px solid #555;
    padding: 0.6rem 3rem;
    background: #2a35e0;
    &:hover{
      color: #fff;
    }
  }
}