@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900|Libre+Baskerville:400,700&display=swap');

//font-family: 'Abril Fatface', cursive;
//font-family: 'Montserrat', sans-serif;


/* Your default CSS. */
*{margin: 0;padding:0;box-sizing: border-box;}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
}

body {
  background: #fff;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 1.5px;
  color: #444;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat', sans-serif;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

button,
input,
textarea {
  letter-spacing: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Montserrat';
  font-weight: 700;
  color: #000;
  letter-spacing: 1.5px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
  font-family: 'Montserrat';
}

h1 {
  font-size: 36px;
  line-height: 70px;
  letter-spacing: 1px;
  margin-bottom: 0px;
}

h2 {
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 0px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0px;
}

h4 {
  font-size: 20px;
  line-height: 27px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-size: 14px;
  line-height: 24px;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
}

p img {
  margin: 0;
}

span {
  font-family: 'Montserrat', sans-serif;
}


/* em and strong */

small {
  font-size: 11px;
  line-height: inherit;
}

/* links */

a,
a:visited {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: 0;
}

a:hover,
a:active {
  text-decoration: none;
  outline: 0;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

p a,
p a:visited {
  line-height: inherit;
  outline: 0;
}


/* list */

ul,
ol {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0;
}

ul {
  margin: 0;
  list-style-type: none;
}

ol {
  list-style: decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
  margin-left: 0px;
}

ul.square {
  list-style: square outside;
}

ul.circle {
  list-style: circle outside;
}

ul.disc {
  list-style: disc outside;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0;
}

ul ul li,
ul ol li,
ol ol li,
ol ul li {
  margin-bottom: 0px;
}

li {
  line-height: 18px;
  margin-bottom: 0px;
}

/* definition list */

dl {
  margin: 12px 0;
}

dt {
  margin: 0;
  color: #11ABB0;
}

dd {
  margin: 0 0 0 20px;
}

button {
  cursor: pointer;
  outline: none;
}
