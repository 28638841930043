body{
  .comingSoon{
    overflow: hidden;
    max-height: 100vh;
    position: relative;
    background-color: #483a9c;
    z-index: 1;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: -1;
    //   height: 100%;
    //   width: 100%;
    //   opacity: 0.4;
    //   background: #2b2727;
    // }
    @media (max-width: 767px) {
      min-height: 100vh;
      max-height: auto;
    }
  }
  .logo{
    width: 350px;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    padding-top: 24px;
    @media (max-width: 767px) {
      width: 311px;
    }
  }
  .cmContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-width: 158rem;
    margin: auto;
    padding: 8.5rem 1.5rem 6rem;
    text-align: center;
    @media (min-width: 1201px) and (max-width: 1599px) {
      padding: 13rem 1.5rem 10rem;
      
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 5rem 1.5rem 9rem;
    }
    @media (max-width: 767px) {
      padding: 5rem 1.5rem 9rem;
    }
    h2{
       width: 100%;
      height: 43px;
      object-fit: contain;
      font-size: 37px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.85px;
      color: #ffffff;
      @media (min-width: 1201px) and (max-width: 1599px) {
        font-size: 5rem;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 4rem;
        line-height: 5rem;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 4rem;
      }
      @media (max-width: 767px) {
        font-size: 2.5rem;
        line-height: 3rem;
      }
      @media (max-width: 500px) {
        font-size: 2rem;
      }
    }
  .Nemo-enim-ipsam-volu {
      width: 326px;
      height: 38px;
      object-fit: contain;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.45;
      letter-spacing: 0.92px;
      text-align: center;
      color: #ffffff;
      margin-top: 30px;
    }
    .react-countdown{
      margin-top: 1rem;
      li{
        margin: 0 4.1rem;
        @media (max-width: 767px) {
          margin: 0px 1.3rem;
        }
        p.digit{
          color: #fff;
          font-size: 52px;
        font-weight: normal;
        line-height: 7rem;
        margin-bottom: 0;
          @media (min-width: 992px) and (max-width: 1200px) {
            font-size: 7rem;
            line-height: 8rem;
          }
          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 6rem;
            line-height: 6rem;
          }
          @media (max-width: 767px) {
            font-size: 5rem;
            line-height: 5rem;
          }
          @media (max-width: 500px) {
            font-size: 3rem;
            line-height: 3rem;
          }
        }
        p.text{
          color: #fff;
          text-align: center;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.7rem;
          margin-left: 0.3rem;
        }
        &:after {
          top: 30%;
          right: -2.6rem;
          font-size: 3rem;
          color: #c7bebe;
            @media (max-width:767px) {
              top: 10px;
            }
            @media (max-width:500px) {
                top: 4px;
                right: -1.6rem;
            }
        }
      }
    }
  }
  .socialLinks{
        position: absolute;
    right: 103px;
    top: 50px;
    /* padding: 3px 15px 1px 4px; */
    /* border-radius: 25px; */
    width: 244px;
    /* height: 15px; */
    object-fit: contain;
    font-size: 13px;
    /* font-weight: bold; */
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #ffffff;
    @media (max-width: 991px) {
        right: 1.5rem;
        top: 1.8rem;
    }
    li{
      display: inline-block;
      a{
        display: block;
        color: #fff;
        letter-spacing: 0;
        padding: 0.3rem;
        margin: 0 0.6rem;
        font-size: 1.6rem;
      }
      &:last-child a{
        padding-right: 0;
        margin-right: 0;
      }
    }
    @media (max-width: 767px) {
     display: none;
    }
  }
  .contactUs{
    position: absolute;
    right: 4rem;
    bottom: 2.3rem;
    color: #fff;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    border-bottom: 1px solid #fff;
    line-height: 1.5rem;
    background: rgba(30, 22, 14, 0.68);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1rem;
    cursor: pointer;
    overflow: hidden;
  }
  .arrowLineDown{
    position: absolute;
    right: 9rem;
    top: 6rem;
    width: 1px;
    height: calc(100% - 12rem);
    background: rgba(255,255,255,0.1);
    overflow: hidden;
    @media (max-width: 767px) {
      right: 1rem;
      top: 0;
      height: calc(100% - 4rem);
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 3rem;
      width: 1px;
      animation: routing 30s ease-in-out 0s infinite;
    }
    @keyframes routing {
      0%{
        top: -3rem;
        background: rgba(255,255,255,0.5);
      }
      50%{
        top: 50%;
        background: rgba(255,255,255,0.5);
      }
      100%{
        top: 100%;
        background: rgba(255,255,255,0.5);
      }
    }
  }
  .slideShowWrapper{
    position: fixed;
    left: 0;
    top: 0;
    z-index: -2;
    min-height: 100vh;
    width: 100%;
    .eachSlide{
      height: 100vh;
    }
  }
}